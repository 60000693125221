<template>
  <div>
    <input type="file" ref="file" style="display: none" />

    <div v-show="show_webcam">
      <div class="camera-modal">
        <video id="webcam" autoplay playsinline class="camera-stream"></video>
        <canvas id="canvas" class="d-none"></canvas>
      </div>
      <div class="camera-view">
        <v-btn
          color="red"
          dark
          absolute
          fab
          bottom
          :style="{ left: '50%', transform: 'translateX(-50%)' }"
          @click="takePicture"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <!-- <v-btn color="white" absolute fab bottom right @click="flipCamera">
          <v-icon>mdi-camera-flip-outline</v-icon>
        </v-btn> -->
        <v-btn color="white" absolute fab bottom left @click="cancel">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-file-input
          accept="image/*"
          label="File input"
          @change="loadImage"
        ></v-file-input>
      </div>
    </div>
    <div v-if="isViewingPicture" class="preview-mode">
      <v-container>
        <v-row no-gutters>
          <v-col> <img id="scanned-img" :src="previewURL" /></v-col>
        </v-row>
        <v-row no-gutters>
          <v-btn color="white" fab top left @click="cancelPreview">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn
              color="green"
              dark
              top
              :style="{ left: '50%', transform: 'translateX(-50%)' }"
              @click="acceptTicket()"
            >
              <!-- <v-icon>mdi-thumb-up</v-icon> -->
              <v-icon>mdi-content-save</v-icon>
              Save
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="green"
              dark
              top
              :loading="workerInProgress"
              :disabled="!uploadToAIStatus"
              :style="{ left: '50%', transform: 'translateX(-50%)' }"
              @click="sendToAI"
            >
              <v-icon>mdi-robot</v-icon>
              Upload to AI
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- <div>
        <img id="scanned-img" :src="previewURL" />
      </div> -->
      <div>
        <img id="gray-img" src="" />
      </div>
      <!-- <div>
        <v-btn
          color="green"
          dark
          top
          :style="{ left: '50%', transform: 'translateX(-50%)' }"
          @click="acceptTicket()"
        >
          <v-icon>mdi-content-save</v-icon>
          Save
        </v-btn>
      </div> -->
      <div>
        <!-- <v-btn
          color="green"
          dark
          top
          :loading="workerInProgress"
          :disabled="!uploadToAIStatus"
          :style="{ left: '50%', transform: 'translateX(-50%)' }"
          @click="sendToAI"
        >
          <v-icon>mdi-robot</v-icon>
          Upload to AI
        </v-btn> -->
        <!-- <v-btn color="white" fab top left @click="cancelPreview">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn> -->
        <v-alert type="error" v-if="!valid_ticket">Invalid ticket</v-alert>
        <div v-if="type == 'free'">
          <emb-ticket-editor
            :ticket="free_ticket"
            :type="type"
          ></emb-ticket-editor>
        </div>
        <div v-if="type == 'user' && user_ticket != null">
          <emb-ticket-editor
            :ticket="user_ticket"
            :type="type"
          ></emb-ticket-editor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Webcam from "webcam-easy";
import { fromBlob, fromURL, blobToURL } from "image-resize-compress";
import { mapGetters } from "vuex";
import { getFirestore, doc, setDoc } from "firebase/firestore";

export default {
  computed: {
    ...mapGetters(["general_info", "lotteries_info", "getUser"]),
  },
  data() {
    return {
      // res_width: 0,
      // imageCapture: null,
      webcamElement: null,
      // webcamStream: null,
      canvasElement: null,
      snapSoundElement: null,
      webcam: null,
      mediaStream: null,
      shouldFaceUser: false,
      isViewingPicture: false,
      show_webcam: true,
      previewURL: null,
      blob: null,
      numbers: "",
      uploadToAIStatus: true,
      worker: null,
      workerInProgress: false,
      workerProgressValue: 0,
      workerProgressPourcentage: 0,
      free_ticket: {
        amount: 0,
        creation_time: new Date(),
        draw_date: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          22,
          59
        ),
        display_date: new Date(),
        type_name: "megamillions",
        numbers: [{ play: "", error: "" }],
        multiplier: false,
      },
      user_ticket: null,
      valid_ticket: true,
      lottery_days: {
        megamillions: [2, 5],
        powerball: [1, 3, 6],
      },
      draw_date_mapping: {
        // Monday, Wednesday, Saturday
        powerball: {
          0: 1, // Sunday
          1: 0, // Monday
          2: 1, // Tuesday
          3: 0, // Wednesday
          4: 2, // Thursday
          5: 1, // Friday
          6: 0, // Saturday
        },
        // Tuesday, Friday
        megamillions: {
          0: 2, // Sunday
          1: 1, // Monday
          2: 0, // Tuesday
          3: 2, // Wednesday
          4: 1, // Thursday
          5: 0, // Friday
          6: 3, // Saturday
        },
      },
    };
  },
  // type: user, picture for user ticket
  // type: free, picture for free ticket
  props: ["order", "product_index", "acceptCallback", "type"],

  mounted() {
    this.$store.dispatch("fetch_general_info");
    // this.startWebcam();
    this.webcamElement = document.getElementById("webcam");
    this.canvasElement = document.getElementById("canvas");
    this.webcam = new Webcam(
      this.webcamElement,
      "environment",
      this.canvasElement,
      null
    );

    this.webcam
      .start()
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.type == "user") {
      this.user_ticket = this.create_user_ticket();
      // console.log("user ticket: ", this.user_ticket);
    }
  },
  destroyed() {
    // this.stopWebcam();
    if (this.webcam != null) {
      this.webcam.stop();
    }
  },
  methods: {
    // startWebcam() {
    //   navigator.mediaDevices
    //     .getUserMedia({
    //       video: { facingMode: "environment", focusMode: "auto" },
    //     })
    //     .then((mediaStream) => {
    //       this.webcamStream = mediaStream;
    //       document.querySelector("video").srcObject = mediaStream;

    //       const track = mediaStream.getVideoTracks()[0];
    //       this.imageCapture = new ImageCapture(track);

    //       return this.imageCapture.getPhotoCapabilities();
    //     })
    //     .then((photoCapabilities) => {
    //       console.log(photoCapabilities);
    //       const settings = this.imageCapture.track.getSettings();
    //       console.log(settings);
    //       this.res_width = photoCapabilities.imageWidth.max;

    //       return this.imageCapture.getPhotoSettings();
    //     })
    //     .then((photoSettings) => {
    //       console.log("Settings: ", photoSettings);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // stopWebcam() {
    //   if (this.webcamStream != null) {
    //     this.webcamStream.getTracks().forEach((track) => {
    //       track.stop();
    //     });
    //   }
    // },
    create_user_ticket() {
      var numbers = [];
      var product = this.order.products[this.product_index];
      var numbersCount = 0;
      if (
        product.ticket.quickPicks != undefined &&
        product.ticket.quickPicks > 0
      ) {
        numbersCount = product.ticket.quickPicks;
      } else {
        numbersCount = product.ticket.grids.length;
      }
      for (var i = 0; i < numbersCount; ++i) {
        numbers.push({ play: "", error: "" });
      }
      return {
        //amount: 0,
        creation_time: new Date(),
        draw_date: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        last_draw_date: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        draw_dates: [],
        //display_date: new Date(),
        type_name: this.order.products[this.product_index].ticket.type_name,
        numbers: numbers,
        multiplier: false,
        error: "",
        dates_error: "",
        multiplier_error: "",
      };
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }

      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], { type: mimeString });
      return blob;
    },
    takePicture() {
      if (this.webcam != null) {
        const blob = this.dataURItoBlob(this.webcam.snap());
        this.webcam.stop();

        this.isViewingPicture = true;
        this.show_webcam = false;

        // quality value for webp and jpeg formats.
        const quality = 80;
        // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
        const width = 1000;
        // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
        const height = "auto";
        // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
        const format = "jpeg";

        // note only the blobFile argument is required
        fromBlob(blob, quality, width, height, format).then((new_blob) => {
          // will output the converted blob file
          this.blob = new_blob;
          blobToURL(new_blob).then((url) => (this.previewURL = url));
        });
      } else {
        this.isViewingPicture = true;
        this.show_webcam = false;
      }
    },
    sendToAI() {
      if (this.type == "free") {
        this.workerInProgress = true;
        this.$store
          .dispatch("send_image_to_ai", {
            image: this.blob,
            order: this.order,
            product_index: this.product_index,
            folder: "free_tickets",
          })
          .then((info) => {
            this.$store
              .dispatch("wait_command", {
                command_id: info.id,
              })
              .then((document) => {
                console.log("(Free) Response from AI: ", document);
                this.free_ticket.image = info.image;
                this.free_ticket.path = info.path;
                this.free_ticket.id = info.id;
                this.$store.dispatch("delete_command", info.id);
                if (
                  document.grids != undefined &&
                  document.grids.length > 0 &&
                  document.grids[0] != ""
                ) {
                  this.free_ticket.numbers[0].play =
                    document.grids[0].replace(":", " (") + ")";
                }
                if (document.type != "") {
                  this.free_ticket.type_name = document.type;
                }
                this.free_ticket.draw_date = this.getFindDrawDate(
                  this.free_ticket.type_name
                );
                this.free_ticket.last_draw_date = this.getFindDrawDate(
                  this.free_ticket.type_name
                );
                if (document.multiplier != undefined) {
                  this.free_ticket.multiplier = document.multiplier;
                }
                this.free_ticket.amount =
                  this.general_info.lotteries[
                    this.free_ticket.type_name
                  ].next_draw.amount;
                this.workerInProgress = false;
                this.uploadToAIStatus = true;
                this.$root.$emit("ticket_updated");
              })
              .catch((error) => {
                console.log("Error:", error);
                this.workerInProgress = false;
                this.uploadToAIStatus = true;
              });
          })
          .catch((error) => {
            console.log("Error:", error);
            this.workerInProgress = false;
            this.uploadToAIStatus = true;
          });
      } else if (this.type == "user") {
        console.log("User ticket order", this.order);

        this.workerInProgress = true;
        this.$store
          .dispatch("send_image_to_ai", {
            image: this.blob,
            order: this.order,
            product_index: this.product_index,
            folder: "tickets",
          })
          .then((info) => {
            this.$store
              .dispatch("wait_command", {
                command_id: info.id,
              })
              .then((document) => {
                // Delete the command
                this.$store.dispatch("delete_command", info.id);

                console.log("(User)Response from AI: ", document);
                this.user_ticket.image = info.image;
                this.user_ticket.path = info.path;
                if (document.grids != undefined) {
                  var numbersCount = document.grids.length;
                  if (numbersCount > this.user_ticket.numbers.length) {
                    numbersCount = this.user_ticket.numbers.length;
                  }
                  for (var index = 0; index < numbersCount; ++index) {
                    var numbers_to_add = {
                      play: document.grids[index].replace(":", " (") + ")",
                      error: "",
                    };
                    this.user_ticket.numbers[index] = numbers_to_add;
                  }
                }
                if (document.type != undefined && document.type != "") {
                  this.user_ticket.type_name = document.type;
                }

                // if (document.first_draw_date != undefined) {
                //   var js_draw_date = document.first_draw_date.toDate();
                //   this.user_ticket.draw_date.setDate(js_draw_date.getDate());
                //   this.user_ticket.draw_date.setMonth(js_draw_date.getMonth());
                //   this.user_ticket.draw_date.setFullYear(
                //     js_draw_date.getFullYear()
                //   );
                // } else {
                this.user_ticket.draw_date = this.getFindDrawDate(
                  this.user_ticket.type_name
                );
                // }
                if (document.last_draw_date != undefined) {
                  var js_last_draw_date = document.last_draw_date.toDate();
                  this.user_ticket.last_draw_date.setDate(
                    js_last_draw_date.getDate()
                  );
                  this.user_ticket.last_draw_date.setMonth(
                    js_last_draw_date.getMonth()
                  );
                  this.user_ticket.last_draw_date.setFullYear(
                    js_last_draw_date.getFullYear()
                  );
                } else {
                  this.user_ticket.last_draw_date = this.getFindDrawDate(
                    this.user_ticket.type_name
                  );
                }
                if (document.multiplier != undefined) {
                  this.user_ticket.multiplier = document.multiplier;
                }
                console.log("Update draw date: ", this.user_ticket);
                this.$root.$emit("ticket_updated"); //like this
                // this.user_ticket.amount =
                //   this.general_info.lotteries[
                //     this.user_ticket.type_name
                //   ].next_draw.amount;
                this.workerInProgress = false;
                this.uploadToAIStatus = true;
              })
              .catch((error) => {
                console.log("Error:", error);
                this.workerInProgress = false;
                this.uploadToAIStatus = true;
              });
          })
          .catch((error) => {
            console.log("Error:", error);
            this.workerInProgress = false;
            this.uploadToAIStatus = true;
          });

        // this.$store
        //   .dispatch("add_ticket_image", {
        //     ticket: this.order["products"][this.product_index],
        //     order: this.order,
        //     product_index: this.product_index,
        //     image: this.blob,
        //   })
        //   .then(() => {
        //     this.$router.back();
        //   });
      }
    },

    changeTimezone(date, ianatz) {
      // suppose the date is 12:00 UTC
      var invdate = new Date(
        date.toLocaleString("en-US", {
          timeZone: ianatz,
        })
      );

      // then invdate will be 07:00 in Toronto
      // and the diff is 5 hours
      var diff = date.getTime() - invdate.getTime();

      // so 12:00 in Toronto is 17:00 UTC
      return new Date(date.getTime() - diff); // needs to substract
    },
    getFindDrawDate(type_name) {
      var new_date_utc = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        22,
        59
      );
      var new_date = this.changeTimezone(new_date_utc, "America/Toronto");

      var current_day = new_date.getDay();
      new_date.setDate(
        new_date.getDate() + this.draw_date_mapping[type_name][current_day]
      );
      return new_date;
    },
    getPoolsFromString(play) {
      var pool_result = [];
      var poolsStr = play.split(" ");

      var numbers = [];
      // Parse first part
      if (poolsStr.length >= 1) {
        var pool1Str = poolsStr[0].split("-");

        for (var numberStrIdx in pool1Str) {
          var parsedNumber = parseInt(pool1Str[numberStrIdx]);
          if (!isNaN(parsedNumber)) {
            numbers.push(parsedNumber);
          }
        }
      }
      pool_result.push({ numbers: numbers });

      // Parse second part
      numbers = [];
      if (poolsStr.length >= 2) {
        var pool2 = parseInt(poolsStr[1].replace("(", "").replace(")", ""));
        if (!isNaN(pool2)) {
          numbers.push(pool2);
        }
      }
      pool_result.push({ numbers: numbers });

      return pool_result;
    },

    validateUserTicket() {
      var error = false;
      this.user_ticket.error = "";
      this.user_ticket.dates_error = "";
      this.user_ticket.multiplier_error = "";

      // Lottery name must be the same as the order
      if (
        this.order.products[this.product_index].ticket.type_name !=
        this.user_ticket.type_name
      ) {
        this.user_ticket.error =
          "Lottery type: " +
          this.user_ticket.type_name +
          " is not the same as the order, it should be a " +
          this.order.products[this.product_index].ticket.type_name;
        return true;
      }

      // // Check if the first draw date fit with the lottery type
      // if (
      //   !this.lottery_days[this.user_ticket.type_name].includes(
      //     this.user_ticket.first_draw_date.getDay()
      //   )
      // ) {
      //   this.user_ticket.dates_error =
      //     "The first draw dates is not valid for lottery: " +
      //     this.user_ticket.type_name;
      //   return true;
      // }

      // Error if there is not draw dates
      if (this.user_ticket.draw_dates.length == 0) {
        this.user_ticket.dates_error = "The draw dates are not valid.";
        return true;
      }

      if (
        this.order.products[this.product_index].ticket.multiplier !=
        this.user_ticket.multiplier
      ) {
        this.user_ticket.multiplier_error =
          "Multiplier not equal to the multiplier order";
        return true;
      }

      // Validate to not have the same number serie more than once
      var numbers_validation = {};
      for (var value of this.user_ticket.numbers) {
        if (value.play in numbers_validation) {
          value.error = value.play + " exist more than once";
          return true;
        } else {
          numbers_validation[value.play] = 0;
        }
      }

      // if (this.user_ticket.draw_date > this.user_ticket.last_draw_date) {
      //   this.user_ticket.dates_error =
      //     "First draw date is after the last draw date, please verify.";
      //   return true;
      // }

      // Add draw dates must be the as as the multiplay in the order
      if (
        this.user_ticket.draw_dates.length !=
        this.order.products[this.product_index].ticket.multiplay
      ) {
        this.user_ticket.dates_error =
          "Lottery dates has " +
          this.user_ticket.draw_dates.length.toString() +
          " draws but the ticket should has a multiplay of " +
          this.order.products[this.product_index].ticket.multiplay.toString();
        return true;
      }

      for (var numbers_idx in this.user_ticket.numbers) {
        var numbers = this.user_ticket.numbers[numbers_idx];
        numbers.error = "";

        var info = this.lotteries_info.lotteries[this.user_ticket.type_name];
        if (info == undefined) {
          numbers.error = "Undefined lottery type";
          error = true;
          continue;
        }

        var pools = this.getPoolsFromString(numbers.play);
        // console.log("pools: ", pools);
        // console.log("info.pools[0].count: ", info.pools[0].count);
        // console.log("info.pools[1].count: ", info.pools[1].count);
        if (
          pools.length != info.pools.length ||
          pools[0].numbers.length != info.pools[0].count ||
          pools[1].numbers.length != info.pools[1].count
        ) {
          numbers.error = "Invalid play";
          error = true;
          continue;
        }

        try {
          for (var i = 0; i < pools.length; ++i) {
            var last_number = 0;
            for (var number_idx in pools[i].numbers) {
              var number = pools[i].numbers[number_idx];
              // Check if number outside min max
              if (number < 1 || number > info.pools[i].max) {
                numbers.error =
                  "A number " +
                  number.toString() +
                  " (in pool: " +
                  (i + 1).toString() +
                  ") outside bound [1," +
                  info.pools[i].max.toString() +
                  "]";
                error = true;
                throw -1;
              }

              // check if number is lower or equal to previous (01-02-05-04 not accepted)
              if (number <= last_number) {
                numbers.error =
                  "Number " +
                  number.toString() +
                  " is lower or equals to previous number";
                error = true;
                throw -1;
              } else {
                last_number = number;
              }
            }
          }
        } catch (err) {
          continue;
        }
      }
      return error;
    },
    getDrawDates(type_name, first_date, last_date) {
      var result = [];
      result.push(this.$formatDateToYYYYMMDD(first_date));

      var next_draw_mapping = {
        powerball: {
          1: 2, // If monday, next draw in 2 days
          3: 3, // If wednesday, next draw in 3 days
          6: 2, // If saturday, next draw in 2 days
        },
        megamillions: {
          2: 3, // If tuesday, next draw in 3 days
          5: 4, // If friday, next draw in 4 days
        },
      };
      var next_date = new Date(first_date);
      while (next_date < last_date) {
        var current_day = next_date.getDay();
        var mapped_day_to_add = next_draw_mapping[type_name][current_day];
        if (mapped_day_to_add == undefined) {
          return [];
        }
        next_date.setDate(next_date.getDate() + mapped_day_to_add);
        result.push(this.$formatDateToYYYYMMDD(new Date(next_date)));
      }

      return result;
    },

    acceptTicket() {
      if (this.type == "free") {
        // console.log("Save free tickets: ", this.free_ticket);
        this.free_ticket.added_by = this.getUser.uid;
        const freeTicketDocRef = doc(
          getFirestore(),
          "free_tickets",
          this.free_ticket.id
        );
        setDoc(freeTicketDocRef, this.free_ticket);
        this.$router.replace("/home");
      } else {
        // Validate ticket
        var all_dates = this.getDrawDates(
          this.user_ticket.type_name,
          this.user_ticket.draw_date,
          this.user_ticket.last_draw_date
        );
        // console.log("All draw dates: ", all_dates);
        this.user_ticket.draw_dates = all_dates;
        this.valid_ticket = !this.validateUserTicket();
        if (this.valid_ticket) {
          // console.log("Order: ", this.order);
          // console.log("User ticket: ", this.user_ticket);

          this.order.products[this.product_index].scan = {
            first_draw_date: this.user_ticket.draw_date,
            last_draw_date: this.user_ticket.last_draw_date,
            grids: this.user_ticket.numbers,
            multiplier: this.user_ticket.multiplier,
            image: this.user_ticket.image,
            path: this.user_ticket.path,
            draw_dates: all_dates,
          };
          this.order.products[this.product_index].ready = true;

          this.$store.dispatch("update_order", {
            update_values: this.order,
            docId: this.order.id,
          });
          this.$router.back();
        }
      }
    },
    cancelPreview() {
      this.isViewingPicture = false;
      this.show_webcam = true;
      this.webcamElement = document.getElementById("webcam");
      // this.startWebcam();
      this.webcam.start();
    },

    cancel() {
      this.$store.commit("stopListeningOrder");
      this.$router.back();
    },
    previewFiles(event) {
      console.log(event.target.files);
    },
    recognizeEvent() {
      this.recognize(this);
    },
    loadImage(file) {
      console.log("Files: ", file);
      if (file != undefined) {
        // this.stopWebcam();
        this.webcam.stop();
        var inputUrl = URL.createObjectURL(file);
        // quality value for webp and jpeg formats.
        const quality = 30;
        // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
        const width = 400;
        // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
        const height = "auto";
        // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
        const format = "jpeg";

        // note only the blobFile argument is required
        fromURL(inputUrl, quality, width, height, format).then((new_blob) => {
          // will output the converted blob file
          this.blob = new_blob;
          blobToURL(new_blob).then((url) => (this.previewURL = url));
        });
        this.blob = file;
        this.isViewingPicture = true;
        this.show_webcam = false;
      }
    },
    async rescale_image() {
      var image = await Image.load(document.getElementById("scanned-img").src);
      var grey = image.grey();
      document.getElementById("gray-img").src = grey.toDataURL();
    },
    // takePicture() {
    //   this.imageCapture
    //     .takePhoto({ imageWidth: 2000 /*this.res_width */ })
    //     .then((blob) => {
    //       createImageBitmap(blob);

    //       this.isViewingPicture = true;
    //       this.blob = blob;
    //       blobToURL(blob).then((url) => (this.previewURL = url));
    //     })
    //     .then((imageBitmap) => {
    //       this.drawCanvas(imageBitmap);
    //       console.log(
    //         `Photo size is ${imageBitmap.width}x${imageBitmap.height}`
    //       );
    //       this.stopWebcam();
    //     })
    //     .catch((error) => console.log(error));
    // },
    // drawCanvas(img) {
    //   const canvas = document.querySelector("canvas");
    //   canvas.width = getComputedStyle(canvas).width.split("px")[0];
    //   canvas.height = getComputedStyle(canvas).height.split("px")[0];
    //   let ratio = Math.min(
    //     canvas.width / img.width,
    //     canvas.height / img.height
    //   );
    //   let x = (canvas.width - img.width * ratio) / 2;
    //   let y = (canvas.height - img.height * ratio) / 2;
    //   canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    //   canvas
    //     .getContext("2d")
    //     .drawImage(
    //       img,
    //       0,
    //       0,
    //       img.width,
    //       img.height,
    //       x,
    //       y,
    //       img.width * ratio,
    //       img.height * ratio
    //     );
    // },
  },
};
</script>

<style scoped>
.camera-modal {
  margin: 0;
  padding: 0;
}
.camera-stream {
  width: 100%;
  max-height: 100%;
}
.camera-modal-container {
  /* position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 24px; */
}
.take-picture-button {
  display: flex;
}
.camera-view {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
}
.preview-mode {
  width: 100%;
  /* height: 100%; */
  /* top: 0;
  left: 0; */
  /* position: absolute; */
  position: relative;
  z-index: 10;
}
</style>
